export default {
  data: function() {
    return {
      isAllChecked: false,
      checkedItems: [],
      removedItems: [],
      uncheckedItems: [],
      defaultKey: 'id',
      isAllUnchecked: false,
      unCheckedAll: false,
    };
  },
  watch: {
    removedItems(val) {
      if(val.length === this.count) {
        this.isAllChecked = false;
        this.checkedItems = [];
      }
    },
    checkedItems(val) {
      if(val.length === this.count) {
        this.isAllChecked = true;
        this.unCheckedAll = false;
        this.removedItems = [];
      }
    },
  },
  methods: {
    checkAll() {
      this.checkedItems = [];
      this.removedItems = [];
      this.uncheckedItems = [];
      if (this.isAllChecked && !this.unCheckedAll) {
        this.isAllChecked = false;
        this.isAllUnchecked = true;
      } else {
        for (let item in this.lists) {
          const checkedKey = this.checkedKey || this.defaultKey;
          this.checkedItems.push(this.lists[item][checkedKey]);
        }
        this.isAllChecked = true;
        this.unCheckedAll = false;
        this.isAllUnchecked = false;
      }
    },
    check(e) {
      const value = this.checkedKey === this.defaultKey ? Number.parseInt(e.target.value) : e.target.value;
      if (this.isAllChecked) {
        if (this.removedItems.includes(value)) {
          this.removedItems = this.removedItems.filter((id) => id !== value);
        } else {
          this.removedItems.push(value);
          this.unCheckedAll = true;
        }
      }
      if ((this.currentCheckedItems || []).includes(value)) {
        if (e.target.checked) {
          this.uncheckedItems = this.uncheckedItems.filter((id) => id !== value);
        } else {
          this.uncheckedItems.push(value);
        }
      }
    },
  },
};
